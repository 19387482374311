import Gateway from "../Gateway";

const route = "/v1/projects";
const routeRolesList = "/v1/projects/roles/list";

const get = async () => Gateway({
  url: route
})

const getRoles = async () => Gateway({
  url: routeRolesList
})

export default {
  get,
  getRoles
};