import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TLogsResponse } from "src/@types/logs";
import Filter, { FTypes } from "src/components/Filter";
import MainLayout from "src/components/MainLayout";
import TablePaginated from "src/components/TablePaginated";
import Logs from "src/infraestructure/services/api/Logs";

const AdminLogs = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [logs, setLogs] = useState<TLogsResponse[]>([]);
    const { t } = useTranslation();

    const handleFilter = async (data: any) => {
        setLoading(true);
        try {
          const getLogs = await Logs.get({
            startDate: data.startDate,
            endDate: data.endDate,
            page: 1,
            limit: 10
        });
        if(getLogs.data) {
            setLogs(getLogs.data);
        }
        setLoading(false);
        } catch(err){
        setLoading(false);
        }
    }

  return (
    <MainLayout>
        <Filter
        filters={[
          {
            name: "startDate,endDate",
            placeholder: t("Pesquisar por") as string,
            type: FTypes.range,
            format: 'YYYY-MM-DD'
          }
        ]}
        onPress={handleFilter}
        loading={loading}
      />

      {/* <TablePaginated 
      
      /> */}
    </MainLayout>
  );
}

export default AdminLogs;