import { Button, Flex } from '@radix-ui/themes';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportData } from 'src/@types/reports';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import TablePaginated from 'src/components/TablePaginated';
import Reports from 'src/infraestructure/services/api/Reports';

// import { Container } from './styles';

const ReportByGame: React.FC = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<ReportData>();
    const [page, setPage] = useState<number>(1);
    const [filters, setFilteres] = useState<any>();

    const handleFilter = async (data: any) => {
        setLoading(true);
        try {
            const reportByBet = await Reports.getReport({
                clientId: data.PersonalId,
                type: "report-by-game",
                page: 1,
                limit: 25,
                startDate: data.startDate,
                endDate: data.endDate
            });
    
            if(reportByBet && reportByBet.data) {
                setFilteres({
                    clientId: data.PersonalId,
                    type: "report-by-game",
                    limit: 25,
                    startDate: data.startDate,
                    endDate: data.endDate
                });
    
                setList(reportByBet.data);
            }
    
            setLoading(false);
        } catch(err) {
            
            setLoading(false);
            
        }

    }
    
    const backPage = async () => {
        let curPage = page - 1;
        const reportByBet = await Reports.getReport({
            clientId: filters.clientId,
            type: "report-by-game",
            page: curPage,
            limit: 25,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if(reportByBet && reportByBet.data.ReportByTResultViewModel) {
            setList(reportByBet.data);
            setPage(curPage);
        }
    }

    const nextPage = async () => {
        let curPage = page + 1;
        const reportByBet = await Reports.getReport({
            clientId: filters.clientId,
            type: "report-by-game",
            page: curPage,
            limit: 25,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if(reportByBet && reportByBet.data.ReportByTResultViewModel) {
            setList(reportByBet.data);
            setPage(curPage);
        }
    }

    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "search",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "PersonalId",
                        options: [
                            {
                                label: "ID do Jogador",
                                value: "PersonalId",
                            }
                        ],
                    },
                    {
                        name: "startDate,endDate",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.range,
                        predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                        format: 'DD-MM-YY'
                    }
                ]}
                loading={loading}
                onPress={handleFilter}
            />

            <TablePaginated 
                data={list?.report_by_tresult_view_model} 
                totalItems={0} 
                keys={[
                    {
                        name: "modified",
                        type: "date"
                    },
                    {
                        name: "player_id",
                        type: "number"
                    },
                    {
                        name: "game_id",
                        type: "number"
                    },
                    {
                        name: "game_name",
                        type: "string"
                    },
                    {
                        name: "provider_name",
                        type: "string"
                    },
                    {
                        name: "bet_amount",
                        sum: true,
                        type: "money"
                    },
                    {
                        name: "win_amount",
                        sum: true,
                        type: "money"
                    },
                    {
                        name: "GGR",
                        sum: true,
                        calculate: 'substract,bet_amount,win_amount',
                        type: "number",
                        children: ((response) => {
                            return(
                                <div>
                                    R${response.bet_amount - response.win_amount}
                                </div>
                            )
                        })
                    }
                ]} 
                perPage={0} 
                onPageChange={function (page: number): void {
                    throw new Error('Function not implemented.');
                } }                
            />
            {filters && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
                {page !== 1 && <Button variant='soft' onClick={() => {
                    backPage();
                }}>Anterior</Button>}
                <div>{page}</div>
                <Button variant='soft' onClick={() => {
                    nextPage();
                }}>Próximo</Button>
            </Flex>}
        </MainLayout>
    );
}

export default ReportByGame;