import { TBonusActiveRequest, TBonusCancelRequest, TBonusRequest } from "src/@types/bonus";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/bonuses";
const routeCancel = "/v1/clients/:id/cancel-bonus/:bonusId";
const projectBonusListRoute = "/v1/projects/bonus/list";
const routeActive = "/v1/clients/:id/transactions/activate-client-bonus";

const getById = async (data: TBonusRequest) => Gateway({url: route, id: data.clientId, filters: [
    {
        name: 'page',
        value: data.page as any
    },
    {
        name: 'pageSize',
        value: data.pageSize as any
    }
]});

const active = async (data: TBonusActiveRequest) => Gateway({url: routeActive,  method: "PUT", id: data.clientId, data: {
    bonusId: data.bonusId,
    value: data.value
}});

const cancel = async (data: TBonusCancelRequest) => Gateway({url: routeCancel, method: "PUT", lastArg:"bonusId", id: data.clientId, data: {
    bonusId: data.bonusId,
    notes: data.notes
}});
const getList = async (id: string) => Gateway({url: projectBonusListRoute, id});

export default {
    getById,
    cancel,
    getList,
    active
};