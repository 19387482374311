import Gateway from "../Gateway";
import { TAffiliatesSearch, TAffiliatesStatistics, TAffiliatesTransactions, TAffiliatesUpdateTransaction, TAffiliatesWithdrawals } from "src/@types/affiliates";

const route = "/v1/affiliates/search";
const routeTransactions = "/v1/affiliates/transactions";
const routeWithdrawals = "/v1/affiliates/:id/withdrawals";
const routeStatistics = "/v1/affiliates/:id/statistics";
const updateTransactionRoute = "/v1/affiliates/update-transaction/:id";

const updateTransaction = async (data: TAffiliatesUpdateTransaction) => Gateway({url: updateTransactionRoute, method: "PUT", id: data.withdrawId, filters: [
    {
        name: "status",
        value: data.status as any,
    },
]});

const getTransactions = async (data: TAffiliatesTransactions) => Gateway({
    url: routeTransactions,
    filters: [
        {
            name: "type",
            value: data.type as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "perPage",
            value: data.perPage as any,
        },
    ]
});

const get = async (data: TAffiliatesSearch) => Gateway({
    url: route,
    filters: [
        {
            name: "email",
            value: data.email as any,
        },
    ]
});

const getWithdrawals = async (data: TAffiliatesWithdrawals) => Gateway({
    url: routeWithdrawals, id: data.affiliateId, filters: [
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "perPage",
            value: data.perPage as any,
        },
    ]
});

const getStatistics = async (data: TAffiliatesStatistics) => Gateway({
    url: routeStatistics, id: data.affiliateId, filters: [
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "perPage",
            value: data.perPage as any,
        },
    ]
});

export default {
    get,
    getWithdrawals,
    getStatistics,
    getTransactions,
    updateTransaction
};