import { Badge, Button, DataList } from '@radix-ui/themes';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { DateRangePicker } from 'rsuite';
import GGR from 'src/infraestructure/services/api/GGR';
import useViewGGRStyles from 'src/styles/components/viewGGRStyles';
import '../../styles/daterangepicker.css';
import { formatCurrency } from 'src/utils';

// Modal.setAppElement('#root');

type TViewGGR = {
    clientId: string;
    clientName: string;
    clientDocument: string;
}

const ViewGGR = ({ clientId, clientName, clientDocument }: TViewGGR) => {
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.667)",
            zIndex: 998,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            background: '#1e2024',
            minHeight: 200,
            minWidth: 400,
            display: 'flex',
            borderRadius: 10,
            justifyContent: 'center',
            border: 'unset !important',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const { t } = useTranslation();
    const { Title, Description } = useViewGGRStyles();

    const [enabledDateRange, setEnabledDateRange] = useState<boolean>(false);

    const [valueDate, setValueDate] = useState<[Date, Date]>([addDays(new Date(), -1), addDays(new Date(), -1)]);

    const [resultGGR, setResultGGR] = useState<{
        casino_sum?: number;
        betting_sum?: number;
    }>();

    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'Last 7 Days',
            value: [addDays(new Date(), -7), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 Days',
            value: [addDays(new Date(), -30), new Date()],
            placement: 'left'
        }
    ];

    useEffect(() => {
        fetchYesterday();
    }, []);

    const fetchYesterday = async () => {
        const getGGR = await GGR.getById({
            clientId,
            date: moment().subtract(1, "days").format("YYYY-MM-DD")
        })
        if (getGGR) {
            setResultGGR(getGGR.data);
        }
    }

    const filterGGR = async (e: Date) => {
        setValueDate([e, e]);
        const getGGR = await GGR.getById({
            clientId,
            date: moment(e).format('YYYY-MM-DD')
        })
        if (getGGR) {
            setResultGGR(getGGR.data);
        }
    }

    return (
        <>
            <Button onClick={() => { setEnabledDateRange(true) }} variant="classic" color="green">{t('Consult_GGR')}</Button>
            <Modal
                isOpen={enabledDateRange}
                onRequestClose={() => setEnabledDateRange(false)}
                style={customStyles}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className={Title}>Pesquisar GGR</div>
                    <DateRangePicker value={valueDate} oneTap onSelect={(e, i) => filterGGR(e)} />
                    <div style={{ marginTop: 20 }}>
                        {clientName &&
                            <div style={{ marginBottom: 5, marginTop: 5 }}><strong>Nome:</strong> {clientName}</div>
                        }
                        {clientName &&
                            <div><strong>CPF:</strong> {clientDocument}</div>
                        }
                    </div>
                    <DataList.Root style={{ marginTop: 30 }}>
                        <DataList.Item align="center">
                            <DataList.Label className={Description} minWidth="88px">SLOTS</DataList.Label>
                            <DataList.Value>
                                <Badge color="jade">
                                    {resultGGR?.casino_sum && formatCurrency(resultGGR?.casino_sum)}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>
                        <div style={{ marginTop: 5 }} />
                        <DataList.Item>
                            <DataList.Label className={Description} minWidth="88px">BETTING</DataList.Label>
                            <DataList.Value>
                                <Badge color="jade">
                                    {resultGGR?.betting_sum && formatCurrency(resultGGR?.betting_sum)}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>

                </div>
            </Modal>
        </>
    );
}

export default ViewGGR;