import { TWithdrawalsRequest } from "src/@types/withdrawals";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/transactions/withdrawals";

const getById = async (data: TWithdrawalsRequest) => Gateway({
    url: route, id: data.clientId, filters: [
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "pageSize",
            value: data.pageSize as any,
        }
    ]
});

export default {
    getById
};