import { TDeposits, TReceiptRequest } from "src/@types/payments";
import Gateway from "../Gateway";

const routeDeposits = "/v1/payments/:id/deposits";
const routeWithdrawals = "/v1/payments/:id/withdrawals";
const routeReceipt = "/v1/payments/:id/receipt";

const getByIdDeposits = async (data: TDeposits) => Gateway({
    url: routeDeposits, id: data.typeId,
    filters: [
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "transactionId",
            value: data.transactionId as any,
        },
        {
            name: "payeeCpf",
            value: data.payeeCpf as any,
        },
        {
            name: "payerCpf",
            value: data.payerCpf as any,
        },
        {
            name: "endToEndId",
            value: data.endToEndId as any,
        },
        {
            name: "status",
            value: data.status as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        }
    ]
});

const getByIdWithDrawals = async (data: TDeposits) => Gateway({
    url: routeWithdrawals, id: data.typeId,
    filters: [
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "transactionId",
            value: data.transactionId as any,
        },
        {
            name: "payeeCpf",
            value: data.payeeCpf as any,
        },
        {
            name: "payerCpf",
            value: data.payerCpf as any,
        },
        {
            name: "endToEndId",
            value: data.endToEndId as any,
        },
        {
            name: "status",
            value: data.status as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        }
    ]
});

const getReceiptById = async (data: TReceiptRequest) => Gateway({
    url: routeReceipt, id: data.typeId,
    filters: [
        {
            name: "transactionId",
            value: data.transactionId as any,
        },
        {
            name: "id",
            value: data.id as any,
        },
        {
            name: "status",
            value: data.status as any,
        },
        {
            name: "endToEndId",
            value: data.endToEndId as any,
        },
        {
            name: "type",
            value: data.type as any,
        }
    ]
});

export default {
    getByIdDeposits,
    getByIdWithDrawals,
    getReceiptById
};