import { TLoginFormRequest, TUserById } from "src/@types/auth";
import Gateway from "../Gateway";

const route = "/v1/auth/login";
const routeByUser = "/v1/users";
const routeGetUser = "/v1/users/me";

const save = async (data: TLoginFormRequest) => Gateway({url: route, method: 'POST', data});
const getById = async (data: TUserById) => Gateway({
  url: routeByUser,
  id: data.userId
});


const getMe = async () => Gateway({
  url: routeGetUser
});

export const isLogged = (): boolean => localStorage.getItem("@App:token") !== null;

const getToken = () => {
  if (isLogged()) {
    return localStorage.getItem("@App:token");
  }
  return undefined;
//   throw new (ClientError as any)("Usuário não está logado.");
};

export default {
  save,
  getToken,
  getById,
  getMe
//   get,
//   logout
};