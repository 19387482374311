import { Box, Button, Flex, Text, Select, Spinner, TextField } from "@radix-ui/themes";
import { ArrowLeftCircleIcon, BookmarkIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TProjectsResponse } from "src/@types/projects";
import { TCreateUserRequest, TUserInfoResponse } from "src/@types/user";
import ControlledCheckboxes from "src/components/ControlledCheckboxes";
import MainLayout from "src/components/MainLayout";
import Users from "src/infraestructure/services/api/Admin/Users";
import Auth from "src/infraestructure/services/api/Auth";
import Projects from "src/infraestructure/services/api/Projects";
import userProfileStyles from "src/styles/components/userProfileStyles";

const CreateUser = () => {

    const { BackButtonStyle } = userProfileStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [projectsList, setProjectsList] = useState<TProjectsResponse[]>();
    const [currentUser, setCurrentUser] = useState<TUserInfoResponse>();

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<TCreateUserRequest>()
    const onSubmit: SubmitHandler<TCreateUserRequest> = async (data) => {
        data.projects = data.projects.filter((p) => p !== null && p !== undefined);
        
        if(id) {
            setLoading(true);
            const request = await Users.edit(id, data);
            setLoading(false);   
        } else {
            setLoading(true);
            const request = await Users.save(data);
            setLoading(false);   
        }
    }
    
    useEffect(() => {
        if(id) {
            fetchUserById(id);
        }
        fetchProjects();
    }, []);

    const fetchUserById = async (id: string) => {
        const getUserInfo = await Auth.getById({
            userId: id
        });

        if(getUserInfo.status === 200) {
            const userInfoData: TUserInfoResponse = getUserInfo.data;
            setValue('email', userInfoData.email);
            setValue('name', userInfoData.name);
            setValue('role', userInfoData.role);
            setValue('projects', userInfoData.projects);
            setCurrentUser(userInfoData);
        }
    }
    

    const fetchProjects = async () => {
        const requestProjects = await Projects.get();
        setProjectsList(requestProjects.data);
    }

    return (
        <MainLayout>
            <div className={BackButtonStyle} onClick={() => {
                navigate('/admin/users');
            }}>
                <ArrowLeftCircleIcon />
                <Text>{t('Voltar')}</Text>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box style={{ padding: 30, background: 'var(--color-panel)', borderRadius: 10 }}>
                    <Flex direction={"column"} gap={'10px'}>
                        <TextField.Root {...register("name", {
                            required: true
                        })} size="2" placeholder="Nome" />
                        {errors.name &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Name_Required')}
                            </p>
                        }
                        <TextField.Root {...register("email", {
                            required: true
                        })} size="2" placeholder="E-mail" />
                        {errors.email &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Email_Required')}
                            </p>
                        }
                        
                        <Controller
                            control={control}
                            name="role"
                            defaultValue={id ? currentUser?.role : undefined}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                <Select.Root value={value} onValueChange={onChange}>
                                    <Select.Trigger />
                                    <Select.Content>
                                        <Select.Group>
                                            <Select.Label>Função</Select.Label>
                                            <Select.Item value="ADMINISTRATOR">ADMINISTRADOR</Select.Item>
                                            <Select.Item value="MODERATOR">MODERADOR</Select.Item>
                                            <Select.Item value="SUPPORT_LEVEL_1">SUPORTE LEVEL 1</Select.Item>
                                            <Select.Item value="SUPPORT_LEVEL_2">SUPORTE LEVEL 2</Select.Item>
                                            <Select.Item value="SUPPORT_LEVEL_3">SUPORTE LEVEL 3</Select.Item>
                                        </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            )}
                        />
                        {errors.role &&
                            <p style={{ color: 'red', fontSize: 12 }}>
                                {t('Role_Required')}
                            </p>
                        }
                        <h3>{t('Projects')}</h3>
                        
                        {projectsList && watch('projects') && id && <ControlledCheckboxes options={projectsList} control={control} name="projects" />}
                        {projectsList && !id && <ControlledCheckboxes options={projectsList} control={control} name="projects" />}

                        <Button type="submit" variant="classic" disabled={loading}>
                            {loading && 
                                <Spinner loading>
                                    <BookmarkIcon />
                                </Spinner>
                            }
                            {!id ? 'Criar Usuário' : 'Editar Usuário'}
                        </Button>
                    </Flex>
                </Box>
            </form>
        </MainLayout>
    );
}

export default CreateUser;