import { TCreateUserRequest } from "src/@types/user";
import Gateway from "../../Gateway";

const route = "/v1/users";
const routeCreate = "/v1/users/create-user";
const routeEdit = "/v1/users/:id/edit";
const routeDelete = "/v1/users/:id/delete";

const get = async (data: {
    page: number;
    limit: number;
}) => Gateway({
    url: route,
    filters:[
        {
            name: 'page',
            value: data.page as any
        },
        {
            name: 'limit',
            value: data.limit as any
        }
    ]
});
const save = async (data: TCreateUserRequest) => Gateway({url: routeCreate, method: 'POST', data});
const remove = async (id: string ) => Gateway({url: routeDelete, method: "DELETE", id});
const edit = async (id: string, data: TCreateUserRequest) => Gateway({
    url: routeEdit, 
    method: "PUT",
    id, 
    data,
});

export default {
    get,
    save,
    edit,
    remove
};