import { Avatar, Badge, Box, Card, Flex, Table, Text } from '@radix-ui/themes';
import { EyeIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TAffiliatesInfo } from 'src/@types/affiliates';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import Affiliates from 'src/infraestructure/services/api/Affiliates';
import { formatCurrency } from 'src/utils';

const AffiliatesList = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [affiliates, setAffiliates] = useState<TAffiliatesInfo>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const handleFilter = async (data: any) => {
        setLoading(true);
        try {
            const affiliates = await Affiliates.get({
                email: data.email
            })
            if(affiliates && affiliates.data) {
                setAffiliates(affiliates.data);
            }
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }


    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "Email",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "email",
                        options: [
                            {
                                label: "Email",
                                value: "email",
                            }
                        ],
                    }
                ]}
                onPress={handleFilter}
                loading={loading}
            />
            <Table.Root variant="surface" mt={'20px'}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Sobrenome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Data de Registro</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Plano de Comissão</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Tipo de Plano</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Qtde Jogadores</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Ações</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                {affiliates &&
                    <Table.Body>
                        <Table.Row>
                            <Table.RowHeaderCell>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}><EyeIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    navigate(`/affiliates/${affiliates.email}`);
                                }} />{affiliates.affiliate_id}</div></Table.RowHeaderCell>
                            <Table.Cell>{affiliates.email}</Table.Cell>
                            <Table.Cell>{affiliates.name}</Table.Cell>
                            <Table.Cell>{affiliates.last_name}</Table.Cell>
                            <Table.Cell>{affiliates.register_date}</Table.Cell>
                            <Table.Cell>{affiliates.plan_name}</Table.Cell>
                            <Table.Cell><Badge>{affiliates.hybrid_id ? "Hibrído" : "CPA"}</Badge></Table.Cell>
                            <Table.Cell>{affiliates.players_count}</Table.Cell>
                            <Table.Cell>

                            </Table.Cell>
                        </Table.Row>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Comissão Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                R${affiliates?.current_wallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                CPA Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                {affiliates?.affiliate_details?.current_cpa_wallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Comissão Disponível
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                {affiliates?.affiliate_details?.available_wallet}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>

                            <Box maxWidth="240px">
                                <Card>
                                    <Flex gap="3" align="center">
                                        <Box>
                                            <Text as="div" size="2" weight="bold">
                                                Saque Pendente
                                            </Text>
                                            <Text as="div" size="2" color="gray">
                                                {Object.keys(affiliates?.affiliate_details?.withdraw_amount_per_brand).length > 0 ? formatCurrency(parseFloat(affiliates?.affiliate_details.withdraw_amount_per_brand[Object.keys(affiliates?.affiliate_details?.withdraw_amount_per_brand)[0]].withdraw_amount)) : ""}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Card>
                            </Box>
                        </div>
                    </Table.Body>
                }
            </Table.Root>
        </MainLayout>
    );
}

export default AffiliatesList;