import { TLogsRequest } from "src/@types/logs";
import Gateway from "../Gateway";

const route = "/v1/users/logs";

const get = async (data: TLogsRequest) => Gateway({
  url: route,
  filters: [
    {
      name: "startDate",
      value: data.startDate as any,
    },
    {
      name: "endDate",
      value: data.endDate as any,
    },
    {
      name: "page",
      value: data.page as any,
    },
    {
      name: "limit",
      value: data.limit as any,
    }
  ]
});

export default {
  get
};