import { TLimitsRequest, TLimitsRequestType } from "src/@types/limits";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/limits";
const removeRestrictionsRoute = "/v1/clients/:id/remove-limits";
const removeLimitType = "/v1/clients/:id/remove-limits/:type";

const getById = async (data: TLimitsRequest) => Gateway({ url: route, id: data.clientId });
const save = async (data: TLimitsRequest) => Gateway({ url: removeRestrictionsRoute, method: "PUT", id: data.clientId });
const update = async (data: TLimitsRequestType) => Gateway({url: removeLimitType, method: "PUT", id: data.clientId, type: data.type});

export default {
    getById,
    save,
    update
};