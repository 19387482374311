import { TEmailsRequest } from "src/@types/emails";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/emails";

const getById = async (data: TEmailsRequest) => Gateway({url: route, id: data.clientId, filters: [
    {
        name: "page",
        value: data.page as any,
    },
    {
        name: "pageSize",
        value: data.perPage as any,
    }
]});

export default {
    getById
};