import { TMessagesRequest } from "src/@types/messages";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/messages";

const getById = async (data: TMessagesRequest) => Gateway({
    url: route, id: data.clientId, filters: [
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "perPage",
            value: data.perPage as any,
        }
    ]
});

export default {
    getById
};