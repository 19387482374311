import { TRestrictionsRequest } from "src/@types/restrictions";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/restrictions";
const removeRestrictionsRoute = "/v1/clients/:id/remove-restrictions";

const getById = async (data: TRestrictionsRequest) => Gateway({url: route, id: data.clientId});

const save = async (data: TRestrictionsRequest) => Gateway({url: removeRestrictionsRoute, method: "PUT", id: data.clientId});

export default {
    getById,
    save
};