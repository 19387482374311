import { TTransactionsReview } from "src/@types/review";
import { TUpdateTransaction } from "src/@types/transactions";
import Gateway from "../../Gateway";

const route = "/v1/review/transactions";
const routeUpdate = "/v1/review/update-transaction/:id/:type";

const get = async (data: TTransactionsReview) => Gateway({
    url: route,
    filters: [
        {
            name: "type",
            value: data.type as any,
        },
        {
            name: "status",
            value: data.status as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        },
    ]
});

const put = async (data: TUpdateTransaction) => Gateway({url: routeUpdate, method: "POST", id: data.transactionId, type: data.action, data});


export default {
    get,
    put
};