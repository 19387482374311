import { TAddTransactionsData, TTransactionsRequest } from "src/@types/transactions";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/transactions/:type";
const routePut = "/v1/clients/:id/transactions/:type";;

const getById = async (data: TTransactionsRequest) => Gateway({
    url: route, id: data.clientId, type: data.type,
    filters: [
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "typeId",
            value: data.typeId as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "pageSize",
            value: data.pageSize as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        }
    ]
});

const put = async (clientId: string, type: string, data: TAddTransactionsData) => Gateway({url: route, method: "PUT", id: clientId, type, data});

export default {
    getById,
    put
};