import { jwtDecode } from "jwt-decode";

export const getBase64 = (file: File, cb: any) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

function formatDate(date: any) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = yyyy + "-" + mm + "-" + dd;
  return date;
}

export const kFormatter = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export function Last7Days() {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var d = new Date();
    d.setDate(d.getDate() - (7 - i));
    result.push(formatDate(d));
  }
  return result;
}

// export const getMonitorType = (type: number) => {
//   return TMonitorsType[type];
// };

export function formataNumeroTelefone(value?: string) {
  if (!value) return "";

  return value
    .replace(/[\D]/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(value[5] !== "9" ? /(\d{4})(\d)/ : /(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)/, "$1");
}

export function formatPhoneNumber(tel?: string) {
  if (!tel) {
    return "";
  }
  tel = tel.replace(/\D/g, "")
  tel = tel.replace(/^(\d)/, " $1")
  tel = tel.replace(/(.{3})(\d)/, "$1($2")
  tel = tel.replace(/(.{6})(\d)/, "$1)$2")
  if (tel.length === 12) {
    tel = tel.replace(/(.{1})$/, "-$1")
  } else if (tel.length === 13) {
    tel = tel.replace(/(.{2})$/, "-$1")
  } else if (tel.length === 14) {
    tel = tel.replace(/(.{3})$/, "-$1")
  } else if (tel.length === 15) {
    tel = tel.replace(/(.{4})$/, "-$1")
  } else if (tel.length > 15) {
    tel = tel.replace(/(.{4})$/, "-$1")
  }
  return tel;
}

export const formatterCur = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function removeDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

// export const exportAsImage = async (el: any, imageFileName: any) => {
//   const canvas = await html2canvas(el);
//   const image = canvas.toDataURL("image/png", 1.0);
//   const downloadLink = document.createElement("a");
//   downloadLink.href = image;
//   downloadLink.download = imageFileName + ".png";
//   downloadLink.click();
// };

export const copyToClipBoard = async (
  copyMe: string,
) => {
  try {
    await navigator.clipboard.writeText(copyMe);
  } catch (err) {
    return err;
  }
};

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function genPassword() {
  var chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password + "@";
}

export function fetchWithTimeout(
  url: string,
  options: any,
  timeout: number = 15000
) {
  try {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("timeout")), timeout)
      ),
    ]);
  } catch (e) {
    console.log("CATCH TIMEOUT");
  }
}

export function validateEmail(email: string) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

export function formataCPF(cpf: string) {
  cpf = cpf.replace(/[^\d]/g, "");

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}


export function toSeoUrl(url: string) {
  return url.toString()               // Convert to string
    .normalize('NFD')               // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-')            // Change whitespace to dashes
    .toLowerCase()                  // Change to lowercase
    .replace(/&/g, '-and-')          // Replace ampersand
    .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
    .replace(/-+/g, '-')             // Remove duplicate dashes
    .replace(/^-*/, '')              // Remove starting dashes
    .replace(/-*$/, '');             // Remove trailing dashes
}

export const stripTags = (str: string) => {
  if (!str) return str;

  if (str === null || str === "") return false;
  else str = str.toString();

  if (str.includes("<img")) return str;

  return str.replace(/(<([^>]+)>)/gi, "");
};

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
}

export function formatCurrency(value: number) {
  const formatado = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return formatado;
}

export const isTokenExpired = (token: string) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken && decodedToken?.exp) {
      return decodedToken?.exp < currentTime;
    }
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};


export const color = (color: string, opacity = 1) =>
  opacity === 1 ? `var(--${color})` : `rgba(var(--${color}-rgb), ${opacity})`;

export const getColorFromStatus = (status: any) => {
  if (status === "Pago" || status === "Entregue" || status === "Aceito" || status === "Completado" || status === "Entregue" || status === "Ganho" || status === "Ativado" || status === "Ganhou" || status === "Sucesso") {
    return "green";
  }

  if (status === "Processando" || status === "Pendente" || status === "Expirado" || status === "Solicitado" || status === "CashOut" || status === "Estornado") {
    return "yellow";
  }

  if (status === "Processado" || status === "Permitido") {
    return "blue";
  }

  if (status === "Falha" || status === "Devolvido" || status === "Cancelado" || status === "CANCELED" || status === "Recusado" || status === "Rejeitado" || status === "Falhou" || status === "Perdido") {
    return "red";
  }

  return "gray";
}

export const formatCurrencyTextField = (value: string) => {
  const numericValue = value.replace(/[^0-9]/g, ""); // Remove caracteres não numéricos
  const floatValue = parseFloat(numericValue) / 100; // Divide por 100 para ajustar centavos
  if (isNaN(floatValue)) return ""; // Se não for um número, retorna string vazia
  return floatValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export function removeCurrencyFormatting(value?: string) {
  // Remove qualquer caractere que não seja número, ponto ou vírgula
  if (value) {
    let numericValue = value.replace(/[^\d,-]/g, '');

    // Substitui a vírgula por um ponto para lidar com decimais
    numericValue = numericValue.replace(',', '.');

    // Converte para número de ponto flutuante
    return parseFloat(numericValue);
  } else {
    return 0;
  }
}

export function handleFilterGateway(
  filters: {
    name: string;
    value: string;
  }[],): string {
  if (!filters || filters.length === 0) {
    return '';
  }
  let stringRequest = '?';

  const validFilters = filters.filter(item => item.value && item.value !== "undefined" && item.value !== undefined);

  validFilters.forEach((item, index) => {
    const separator = index === validFilters.length - 1 ? '' : '&';
    stringRequest += `${item.name}=${encodeURIComponent(item.value)}${separator}`;
  });

  return stringRequest;
}