import { TDataPut } from "src/@types/admin";
import Gateway from "../../Gateway";

const route = "/v1/admin/config/payments";
const routePut = "/v1/admin/config/payments/edit";

const get = async () => Gateway({
    url: route
});
const put = async (data: TDataPut) => Gateway({url: routePut, method: "PUT", data, timeout: 60000});

export default {
    get,
    put
};