import { IconButton, Spinner, Tooltip } from '@radix-ui/themes';
import axios from 'axios';
import { CheckIcon, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TDefaultResponse } from 'src/@types/transactions';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import TablePaginated from 'src/components/TablePaginated';
import TransactionsPending from 'src/infraestructure/services/api/Transactions/Pending';

const PendingTransactions: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<TDefaultResponse | any>();
    const [filters, setFilteres] = useState<any>();

    const [loadingAction, setLoadingAction] = useState<string>("");
    const [loadingId, setLoadingId] = useState<string>("");

    const { t } = useTranslation();

    const handleFilter = async (data: any) => {
        setLoading(true);
        try {
            const getPendingTransactions = await TransactionsPending.get({
                status: data.status,
                type: data.type,
                page: "1",
                limit: "10"
            })
            setFilteres(data);
    
            if (getPendingTransactions && getPendingTransactions.data) {
                setList(getPendingTransactions);
            } else {
                setList([]);
            }
    
            setLoading(false);
        } catch(err){ 
            if (axios.isAxiosError(err) && err?.response?.status === 404) {
                setList([])
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        handleFilter({
            type: "all",
            status: "pending"
        });
    }, []);

    const actionUpdate = async (id: string, action: string) => {
        setLoadingAction(action);
        setLoadingId(id);
        const actionUpdate = await TransactionsPending.put({
            transactionId: id,
            action: action
        });

        if(actionUpdate && actionUpdate.data) {
            console.log(actionUpdate);
        }
        
        setLoadingAction("");
        setLoadingId("");

        const getPendingTransactions = await TransactionsPending.get(filters)
        if (getPendingTransactions && getPendingTransactions.data) {
            setList(getPendingTransactions);
        } else {
            setList({});
        }
    }

    const handlePageChange = async (page: number) => {
        console.log("page", page);

        const curFilteres = { ...filters };
        curFilteres['page'] = page;

        console.log(curFilteres);

        const getPendingTransactions = await TransactionsPending.get(curFilteres)

        setFilteres(curFilteres);

        if (getPendingTransactions && getPendingTransactions.data) {
            setList(getPendingTransactions);
        }
    }

    return (
        <MainLayout>
            <Filter filters={[
                {
                    name: "status",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.select,
                    predefinedValue: "pending",
                    options: [
                        {
                            label: "Pending",
                            value: "pending",
                        },
                        {
                            label: "Delivered",
                            value: "delivered",
                        },
                        {
                            label: "Canceled",
                            value: "canceled",
                        },
                    ],
                },
                {
                    name: "type",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.select,
                    predefinedValue: "all",
                    options: [
                        {
                            label: "All",
                            value: "all",
                        },
                        {
                            label: "Adicionar Bônus",
                            value: "add_bonus_to_client",
                        },
                        {
                            label: "Adicionar Pagamento",
                            value: "add_payment_to_client",
                        },
                        {
                            label: "Adicionar Saldo",
                            value: "add_balance_to_client",
                        },
                    ],
                }
            ]} onPress={handleFilter} loading={loading} />

            <TablePaginated
                totalItems={list && Object.keys(list).length > 0 ? list?.pagination.total_items : 0}
                data={list?.data}
                isPaginated={true}
                keys={[
                    {
                        name: 'id',
                        type: 'string'
                    },
                    {
                        name: 'agent_name',
                        type: 'string'
                    },
                    {
                        name: 'playerCpf',
                        type: 'string'
                    },
                    {
                        name: 'player_id',
                        type: 'string'
                    },
                    {
                        name: 'player_name',
                        type: 'string'
                    },
                    {
                        name: 'payment_id',
                        type: 'string'
                    },
                    {
                        name: 'payment_system',
                        type: 'string'
                    },
                    {
                        name: "payment_name",
                        type: "string"
                    },
                    {
                        name: 'bonus_id',
                        type: 'string'
                    },
                    {
                        name: 'value',
                        type: 'money'
                    },
                    {
                        name: 'notes',
                        type: 'string'
                    },
                    {
                        name: 'status',
                        type: 'tag'
                    },
                    {
                        name: 'date',
                        type: 'string'
                    },
                    {
                        name: 'actions',
                        type: "string",
                        children: (response: any) => {
                            if(response.status !== "pending") {
                                return(
                                    <></>
                                )
                            } else {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                        {loadingId !== response.id && <Tooltip content={"Aceitar"}>
                                            <IconButton color='green' radius='full' onClick={() => actionUpdate(response.id, "accept")}>
                                                <CheckIcon size={20} />
                                            </IconButton>
                                        </Tooltip>}
                                        
                                        {loadingId !== response.id && <Tooltip content={"Recusar"}>
                                            <IconButton color='red' radius='full' onClick={() => actionUpdate(response.id, "reject")}>
                                                <X size={20} />
                                            </IconButton>
                                        </Tooltip>}
    
                                        {loadingId === response.id && loadingAction === "accept" && <Tooltip content={"Aceitar"}>
                                            <IconButton color='green' radius='full'>
                                                <Spinner />
                                            </IconButton>
                                        </Tooltip>}
    
                                        {loadingId === response.id && loadingAction === "reject" && <Tooltip content={"Recusar"}>
                                            <IconButton color='red' radius='full'>
                                                <Spinner />
                                            </IconButton>
                                        </Tooltip>}
                                    </div>
                                )
                            }
                        }
                    },
                ]}
                perPage={10}
                onPageChange={handlePageChange}
            />
        </MainLayout>
    );
}

export default PendingTransactions;