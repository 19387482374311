import { TBetSelectionsType } from "src/@types/reports";
import Gateway from "../Gateway";

const routeSelections = "/v1/clients/bet-selections";

const getBetSelections = async (data: TBetSelectionsType) => Gateway({
    url: routeSelections,
    filters: [
        {
            name: "betId",
            value: data.betId as any,
        },
        {
            name: "typeId",
            value: data.type as any,
        }
    ]
});

export default {
    getBetSelections
};