import { TGgrRequest } from "src/@types/ggr";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/ggr";

const getById = async (data: TGgrRequest) => Gateway({
    url: route, id: data.clientId, filters: [
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "date",
            value: data.date as any,
        }
    ]
});

export default {
    getById
};