import axios from 'axios';
import Auth from './Auth';

const api = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: api,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Auth.getToken();
    const projectId = JSON.parse(localStorage.getItem('@App:currentProject') || '{}').id;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (projectId) {
      config.headers['Project-Id'] = projectId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
