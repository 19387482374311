import { addDays, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TDefaultResponse, TTransactionsRequest } from 'src/@types/transactions';
import Transactions from 'src/infraestructure/services/api/Transactions';
import Filter, { FTypes } from '../Filter';
import TablePaginated from '../TablePaginated';
import { Flex, Select } from '@radix-ui/themes';

type TClientTransactionsProps = {
    personalId: string;
}

const ClientTransactions = ({ personalId }: TClientTransactionsProps) => {

    const { id } = useParams();

    const [transactionsList, setTransactionsList] = useState<TDefaultResponse>();
    const [saveFilters, setSaveFilters] = useState<TTransactionsRequest>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [perPage, setPerPage] = useState<number | string>(25);

    const [totalAmount, setTotalAmount] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const handleFilter = async (data: any) => {
        setLoading(true);
        setPerPage(25);

        try{
            if (id) {
                setCurrentPage(1);
                const transactions = await Transactions.getById({
                    clientId: id,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    type: data.transactionType,
                    page: 1,
                    limit: 20,
                    pageSize: 20
                });
    
                setSaveFilters({
                    clientId: id,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    type: data.transactionType,
                    limit: 20,
                    pageSize: 20
                });
    
                if (transactions) {
                    setTransactionsList(transactions);
                    setTotalItems(transactions.pagination.total_items);
                    if (transactions.total_amount) {
                        setTotalAmount(transactions.total_amount);
                    }
                }
            }
            setLoading(false);
        }catch(err){
            setLoading(false);
        }

    }

    const getAllTransactions = async () => {
        if (id) {
            try{ 
                const getAll = await Transactions.getById({
                    type: "all-transactions",
                    clientId: id,
                    page: 1,
                    startDate: moment(new Date()).format("DD-MM-YY"),
                    endDate: moment(new Date()).format("DD-MM-YY"),
                    limit: 20
                })
    
                setSaveFilters({
                    clientId: id,
                    startDate: moment(new Date()).format("DD-MM-YY"),
                    endDate: moment(new Date()).format("DD-MM-YY"),
                    type: "all-transactions",
                    limit: 20
                });
    
                if (getAll && getAll.data) {
                    setTransactionsList(getAll);
                }
            }catch(err){}
            
        }
    }

    useEffect(() => {
        getAllTransactions();
    }, []);

    const handleChangePage = async (page: number) => {
        if (id && saveFilters) {
            setCurrentPage(page);

            const transactions = await Transactions.getById({
                ...saveFilters,
                page: page
            });

            if (transactions) {
                setTransactionsList(transactions);
            }
        }
    }

    const getKeysFromType = (key: string) => {
        if (key === "all-transactions") {
            return [
                {
                    name: 'PersonalId',
                    type: 'string',
                    children: ((response: any) => {
                        return (
                            <div>{personalId}</div>
                        )
                    })
                },
                {
                    name: 'created',
                    type: 'string'
                },
                {
                    name: 'amount',
                    sum: true,
                    type: 'money'
                },
                {
                    name: 'balance',
                    type: 'money'
                },
                {
                    name: 'game',
                    type: 'string'
                },
                {
                    name: 'document_type_name',
                    type: 'string'
                },
                {
                    name: "payment_system_name",
                    type: "string"
                },
                {
                    name: 'note',
                    type: 'string'
                },
                {
                    name: 'document_id',
                    type: 'string'
                },
                {
                    name: 'bet_id',
                    type: 'string'
                }
            ]
        } else if (key === 'bets' || key === 'bets-increasing-earnings') {
            return [
                {
                    name: 'document_id',
                    type: 'string'
                },
                {
                    name: 'amount',
                    sum: true,
                    predefinedAmount: totalAmount,
                    type: 'money'
                },
                {
                    name: 'created',
                    type: 'string'
                },
                {
                    name: 'after_balance',
                    type: 'money'
                },
                {
                    name: 'game',
                    type: 'string'
                },
                {
                    name: 'payment_system',
                    type: 'string'
                },
                {
                    name: 'note',
                    type: 'string'
                },
                {
                    name: 'bet_id',
                    type: 'string'
                },
            ]
        } else if (key === 'sportbook' || key === 'bet-builder') {
            return [
                {
                    name: 'document_id',
                    type: 'string'
                },
                {
                    name: 'bet_id',
                    type: 'string'
                },
                {
                    name: 'amount',
                    sum: true,
                    predefinedAmount: totalAmount,
                    type: 'money'
                },
                {
                    name: 'created',
                    type: 'string'
                },
                {
                    name: 'after_balance',
                    type: 'money'
                },
                {
                    name: 'payment_system',
                    type: 'string'
                },
                {
                    name: 'note',
                    type: 'string'
                },
            ]
        }
        return [
            {
                name: 'document_id',
                type: 'string'
            },
            {
                name: 'amount',
                sum: true,
                type: 'money'
            },
            {
                name: 'created',
                type: 'string'
            },
            {
                name: 'after_balance',
                type: 'money'
            },
            {
                name: 'payment_system',
                type: 'string'
            },
            {
                name: 'note',
                type: 'string',
                children: ((response: any) => {
                    return (
                        <div>{response.note}</div>
                    )
                })
            }
        ]
    }

    const handleChangePerPage = async (e: string) => {
        setPerPage(e);
        if (saveFilters) {
            if (e !== "todos") {
                const transactions = await Transactions.getById({
                    ...saveFilters,
                    limit: parseInt(e),
                    pageSize: parseInt(e),
                    page: currentPage
                });

                if (transactions) {
                    setTransactionsList(transactions);
                }
            } else {
                const transactions = await Transactions.getById({
                    ...saveFilters,
                    limit: totalItems,
                    pageSize: totalItems
                });

                if (transactions) {
                    setTransactionsList(transactions);
                }
            }
        }
    }

    return (
        <>
            <Filter showTitle={false} filters={[
                {
                    name: "transactionType",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.select,
                    predefinedValue: "all-transactions",
                    options: [
                        {
                            label: "Todos",
                            value: "all-transactions",
                        },
                        {
                            label: "Depósito",
                            value: "deposits",
                        },
                        {
                            label: "Saque",
                            value: "withdrawals",
                        },
                        {
                            label: "Correção de Bônus",
                            value: "bonus-correction",
                        },
                        {
                            label: "Sportbook",
                            value: "sportbook",
                        },
                        {
                            label: "BetBuilder",
                            value: "bet-builder",
                        },
                        {
                            label: "Bets",
                            value: "bets",
                        },
                        {
                            label: "Aumento de Ganho",
                            value: "bets-increasing-earnings",
                        },
                    ],
                },
                {
                    name: "startDate,endDate",
                    placeholder: t("Pesquisar por") as string,
                    type: FTypes.range,
                    predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                    format: 'DD-MM-YY'
                }
            ]} onPress={handleFilter} loading={loading} />

            {transactionsList && transactionsList.data.length > 0 &&
                <TablePaginated
                    data={transactionsList.data}
                    totalItems={totalItems}
                    keys={getKeysFromType(saveFilters?.type ?? "all-transactions")}
                    perPage={perPage !== "todos" ? perPage as number : totalItems}
                    onPageChange={handleChangePage} />
            }


            {totalItems > 24 && saveFilters?.type === "all-transactions" && saveFilters && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}><Select.Root defaultValue={String(perPage)} onValueChange={(e) => handleChangePerPage(e)}>
                <Select.Trigger />
                <Select.Content>
                    <Select.Group>
                        <Select.Label>{t('Select_Per_Page')}</Select.Label>
                        <Select.Item value="25">25</Select.Item>
                        <Select.Item value="50">50</Select.Item>
                        <Select.Item value="todos">Todos</Select.Item>
                    </Select.Group>
                </Select.Content>
            </Select.Root></Flex>}
        </>
    )
}

export default ClientTransactions;