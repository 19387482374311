import { TBlockUserRequest } from "src/@types/clients";
import Gateway from "../Gateway";

const route = '/v1/clients/:id/block-status';

const put = async (data: TBlockUserRequest) => Gateway({ url: route,  method: "PUT", id: data.clientId, data: { note: data.note }});

export default {
    put
};
