import { TDepositsRequest } from "src/@types/deposits";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/logins";

const getById = async (data: TDepositsRequest) => Gateway({
    url: route, id: data.clientId, filters: [
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "typeId",
            value: data.typeId as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "pageSize",
            value: data.pageSize as any,
        }
    ]
});

export default {
    getById
};